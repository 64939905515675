import React, { FC } from 'react';
import { DangerouslySetInnerHtml } from 'layout';
import classNames from 'classnames';

import './PageIntroduction.scss';

import { IPageIntroduction } from './model';

const PageIntroduction: FC<IPageIntroduction> = ({ pageIntroduction, className }) => (
  <DangerouslySetInnerHtml
    html={pageIntroduction}
    className={classNames('page__introduction', className)}
  />
);

export default PageIntroduction;
