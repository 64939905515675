import React, { FC, useState, useEffect, useRef, useMemo } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import useClickOutside from 'hooks/useClickOutside';

import { IFilterComponent } from './model';
import './FilterComponent.scss';
import './FilterComponentExtends.scss';
import './FilterComponentRTL.scss';

const ESCAPE_KEY = 'Escape';
const ESCAPE_KEY_CODE = 27;

const FilterComponent: FC<IFilterComponent> = ({
  productsCategories,
  filterDefaultText,
  currentCategory,
}) => {
  const wrapperFilterRef = useRef(null);

  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);

  const categories = useMemo(() => {
    return productsCategories?.[0]?.categories.reduce(
      (acc: { url: string; name: string }[], { url }) => [...acc, url?.[0]],
      []
    );
  }, [productsCategories]);

  const handlerVisibleFilter = useMemo(
    () => () => {
      setIsFiltersVisible(!isFiltersVisible);
    },
    [isFiltersVisible]
  );

  const handlerKeyEscapeFilters = useMemo(
    () => (e: KeyboardEvent) => {
      const isEscapeKeyPressed = e.key === ESCAPE_KEY || e.keyCode === ESCAPE_KEY_CODE;

      if (isEscapeKeyPressed && isFiltersVisible) {
        setIsFiltersVisible(!isFiltersVisible);
      }
    },
    [isFiltersVisible]
  );

  const handlerByScrollEscapeFilters = useMemo(
    () => () => {
      setIsFiltersVisible(false);
    },
    []
  );

  const handlerOnBlurEscapeFilters = useMemo(
    () => (filterState: boolean) => () => {
      setIsFiltersVisible(filterState);
    },
    []
  );

  useEffect(() => {
    window.addEventListener('keydown', handlerKeyEscapeFilters);
    window.addEventListener('scroll', handlerByScrollEscapeFilters);

    return () => {
      window.removeEventListener('keydown', handlerKeyEscapeFilters);
      window.removeEventListener('scroll', handlerByScrollEscapeFilters);
    };
  });

  useClickOutside(wrapperFilterRef, handlerOnBlurEscapeFilters(false));

  return (
    <div className="filter-component" ref={wrapperFilterRef}>
      <button
        className={classNames('filter-component__block', {
          'filter-component__block--extended': currentCategory[0].length > 35,
        })}
        type="button"
        onClick={handlerVisibleFilter}
      >
        <span className="filter-component__fixed-text">{filterDefaultText}</span>
        <div className="filter-component__show-result">{currentCategory[0]}</div>
        <span
          className={classNames(`filter-component__arrow`, {
            'icon-arrow-down--filter-up': isFiltersVisible,
            'icon-arrow-down--filter-down': !isFiltersVisible,
          })}
        />
      </button>
      <div
        className={classNames(`filters-categories`, {
          [`filters-categories--vissible`]: isFiltersVisible,
        })}
      >
        {categories?.map((category) =>
          category?.name && category?.url ? (
            <Link key={category.url} to={category.url} className="filters-categories__link">
              {category.name}
            </Link>
          ) : null
        )}
      </div>
    </div>
  );
};

export default FilterComponent;
