import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import Button from 'gatsby-theme-husky/src/common/Button';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import ReviewStars from 'gatsby-theme-husky/src/components/ReviewStars';
import BazaarvoiceStars from 'gatsby-theme-husky/src/components/BazaarvoiceStars';
import { IProductItem } from 'gatsby-theme-husky/src/components/ProductItem/model';
import ProductItemTitle from './ProductItemTitle';

import './ProductItem.scss';
import './ProductItemExtends.scss';

const ProductItem: FC<IProductItem> = ({
  title,
  productURL,
  image,
  amountCondoms,
  buttonText,
  buttonAriaLabel,
  sku,
  ean,
}) => {
  const {
    umbracoHome: {
      defaultCompositions: {
        commonSettings: { showReviewPanel },
      },
    },
  } = useStaticQuery(graphql`
    query {
      umbracoHome {
        defaultCompositions {
          commonSettings {
            showReviewPanel
          }
        }
      }
    }
  `);

  const isReviewPanelVisible = !!Number(showReviewPanel);

  return (
    <Link to={productURL} className="product-item" aria-label={buttonAriaLabel || buttonText}>
      {amountCondoms ? (
        <div className="product-item__pack">{amountCondoms}</div>
      ) : (
        <div className="product-item__pack empty" />
      )}
      <GatsbyImage
        className="product-item__image"
        isLazyLoading
        fluid={image?.[0].localImage?.childImageSharp.fluid}
        alt={image?.[0].properties?.imageAltLabel}
      />
      <ProductItemTitle title={title} />
      <div className="product-item__bottom">
        {isReviewPanelVisible ? (
          <ReviewStars sku={sku} showReview={false} className="product-item__reviews" />
        ) : null}
        {ean ? (
          <div className="bazarvoice-container">
            <BazaarvoiceStars ean={ean} dataBvShow="inline_rating" />
          </div>
        ) : null}
        <Button
          link={productURL}
          btnColorVariant="dark"
          classes="product-item__button"
          ariaLabel={buttonAriaLabel || buttonText}
        >
          {buttonText.toUpperCase()}
        </Button>
      </div>
    </Link>
  );
};

export default ProductItem;
