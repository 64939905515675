import React, { FC } from 'react';

import { IPropsPaginationItem } from './models';

const PaginationItem: FC<IPropsPaginationItem> = ({ handlePagingClick, item, activePage }) => {
  return (
    <li data-test="PaginationItem" className={activePage === item ? 'active' : ''}>
      <button type="button" aria-label="paginationItem" onClick={handlePagingClick}>
        {item + 1}
      </button>
    </li>
  );
};

export default PaginationItem;
